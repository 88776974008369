import React from "react";
import Layout from "../../Components/Layout/Layout";
import bgimage from '../../Images/Hajj/1.jpg';
import logo from'../../Images/Logo/logo.png';
import { useNavigate } from "react-router-dom";
function ConfirmationMessage(){
    const navigation=useNavigate();

    const GoHomePage =()=>{
        navigation('/');
    };
    const GoPackage =()=>{
        navigation('/umrah_packages', { state: 9 });
    };
    return(
        <>
        <Layout>
            <div>
                <img src={bgimage}/>
            </div>
            <div className="container">
                <div className="text-center mt-5">
                    <img style={{width:'25em',height:'auto'}}  src={logo}/>
                    <h5 className="mt-4">Thank you for registering your interest in Hajj 2024 with Al Hijaz Tours Ltd.</h5>
                    <p className="mt-3">Alhijaz Tours is well known within the Hajj and Umrah industry. We have a highly experienced team who know the ins and outs of Umrah and Hajj. We have immense experience on how traveling and accommodation work within Makkah and Medinah so we can make it easier for all pilgrims. Additionally, we have an abundance of knowledge on how one needs to perform their Hajj or Umrah and what they will need to take with them on their journey. We provide all the necessary information and keep our pilgrims updated at all times to make your travels easier. Our highly experienced and well versatile team is ready to take your queries.</p>
                    
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 text-center">
                    <button onClick={GoPackage} style={{width:'70%'}} className="btn mb-2 fw-bold btn-success ">Explore Our Upcoming Umrah Packages</button>
                    </div>
                    <div className="col-md-6 text-center">
                    <button onClick={GoHomePage} style={{width:'70%'}} className="btn fw-bold btn-info ">Go To Home Page</button>
                    </div>
                </div>
            </div>
        </Layout>
        </>
    );
}

export default ConfirmationMessage;